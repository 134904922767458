import React, { createContext, useContext } from 'react'

// queries
import { useStudio } from '@app/queries'

// components
import Spinner from '@components/Spinner'
import FetchError from '@components/FetchError'

// Create the context with a default value of null
const StudioContext = createContext(null)

// Hook to use it all over the app
export const useStudioCtx = () => {
    return useContext(StudioContext)
}

export const StudioContextProvider = ({ children }) => {
    const { data, isLoading, isError } = useStudio()

    if (isLoading) {
        return <Spinner mode='FULLSCREEN' />
    }

    if (isError) {
        return <FetchError />
    }

    return <StudioContext.Provider value={data}>{children}</StudioContext.Provider>
}
