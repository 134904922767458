import React from 'react'
import * as css from './index.module.scss'

export default function Spinner({ mode }) {
    let wrapperClass = ''
    let ballStyles = { backgroundColor: 'var(--color-couscous)' }

    if (mode === 'SECTION') {
        wrapperClass = 'top-md bottom-md'
        ballStyles = { backgroundColor: 'var(--color-couscous)' }
    }

    if (mode === 'FULLSCREEN') {
        wrapperClass = css.fullscreen
        ballStyles = { backgroundColor: 'var(--color-couscous)' }
    }

    if (mode === 'BUTTON') {
        wrapperClass = ''
        ballStyles = { backgroundColor: 'var(--color-white)' }
    }

    return (
        <div className={wrapperClass}>
            <div className={css.spinner}>
                <div className={css.ball1} style={ballStyles} />
                <div className={css.ball2} style={ballStyles} />
                <div style={ballStyles} />
            </div>
        </div>
    )
}
