import React from 'react'
import { IconYogaMudraAnjali } from '@constants/constIcons'
import * as css from './index.module.scss'

export default function FetchError({ mode = '', showReload = true }) {
    function refreshPage() {
        window.location.reload()
    }

    const component = (
        <div className={css.wrapper}>
            <div className={css.content}>
                <IconYogaMudraAnjali className={css.icon} />
                <p className={css.text}>
                    Ocurrió un error al cargar los datos. Refresca tu navegador, si el problema persiste reintenta en
                    unos minutos.
                </p>
                {showReload && (
                    <button className='button bg-mandy' onClick={refreshPage}>
                        Reintentar
                    </button>
                )}
            </div>
        </div>
    )

    if (mode === 'SECTION') {
        return (
            <section className='section'>
                <div className='elastic-centering'>{component}</div>
            </section>
        )
    }

    return component
}
