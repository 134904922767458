export default async function fnFetch(method, route, values = {}) {
    const options = {
        method: method,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        ...(method !== 'GET' && { body: JSON.stringify(values) }),
    }

    try {
        const response = await fetch(process.env.GATSBY_TRIBU_API_URL + route, options)
        const json = await response.json()
        return response.ok ? Promise.resolve(json) : Promise.reject(json)
    } catch (error) {
        return Promise.reject(error)
    }
}
